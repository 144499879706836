/**
 * module: 定义用户相关的actions
 * author: Teacher Tang
 * date: 2018-09-06
 */

import types from '../types';
import { createAction } from 'redux-actions';
import ywk from 'utils';

/**
 * 获取用户信息
 */
export const getProfile = createAction(types.GET_USER, () => {
  return ywk.getJson('/v1.1/sys/loginInfos', {});
});

export const getUserPermission = createAction(types.GET_PERMISSION, (entityUuid, userUuid) => {
  return ywk.getJson(`/principal.permission.list`, {
    entityId: entityUuid,
    productId: 'clouderworkorg',
    resource: 'backend',
    userId: userUuid,
    onlyUser: 1,
  });
});
/**
 * 存储用户数据到state
 */
export const saveProfile = createAction(types.SAVE_USER, user => {
  return { user };
});

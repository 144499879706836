import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { intersection } from 'lodash';
import ywk from 'utils';
import Cookies from 'js-cookie';
import $ from 'jquery';

const toUrl = ywk.getQueryString('toUrl') || '';
const fromUrl = ywk.getQueryString('fromUrl') || '';
// 先登录验证，确定登录后，如果只有一家企业，但是没有激活，需要同步激活该企业后，进入首页。主要是因为登录校验函数不能是异步的，会报错
const activateEntity = (userUuid, entityUuid) => {
  $.ajax({
    url: `/api/v1.0/users/${userUuid}/entities/${entityUuid}`,
    type: 'post',
    async: false,
    dataType: 'json',
    headers: {
      token: Cookies.get('cwsso_token'),
    },
    success: function(res) {
      if (res.code === '0') {
        window.location.href = '/admin';
      }
    },
  });
};
// 定义权限路由 (需要登录或需要权限均在此判断)
const AuthRoute = ({ component: Component, profile: loginInfo, ...rest }) => {
  useEffect(() => {
    (() => {
      if (rest.meta.pointTitle) {
        // 需要埋点页面标题的页面
        window.AnalysysAgent.pageView(rest.meta.pointTitle);
      }
    })();
  }, [rest.meta.pointTitle]);
  return (
    <Route
      {...rest}
      component={props => {
        const { userUuid, orgWeb = {}, currentEntityUuid, profile = {} } = loginInfo;
        const location = window.location;

        if (userUuid) {
          const isManager = orgWeb.isManager || 0;
          const adminEntityList = orgWeb.adminEntityList || [];
          // 所有的权限列表（仅子管理员登录时才有的字段）
          const permissionList = orgWeb.permissionList || [];
          const permissionTags = permissionList.map(item => item.resource || item.permissionTag);
          // 配置在路由表中对应的权限Tag
          const authId = (rest.meta && rest.meta.authId) || [];
          const subAdminHasAuth =
            authId[0] === '-1' || intersection(authId, permissionTags).length > 0;
          let memberType;
          try {
            memberType = profile.org.member.memberType;
          } catch (err) {}
          const hasAuth = (() => {
            if (/^\/admin/.test(location.pathname)) {
              /**
               *  以/admin开头的链接：管理中心：控制台，组织架构管理，应用管理等菜单
               * 1. 是主管理员即可
               * 2. 是子管理员根据authId判断是否有权限即可
               */
              return isManager === 1 || (isManager === 2 && subAdminHasAuth);
            } else if (/^\/user/.test(location.pathname)) {
              /**
               * 以/user 开头的链接：个人中心：我的订单等菜单
               * 判断是内部成员即可 1 正式 2 试用
               */
              return memberType === 1 || memberType === 2;
            } else {
              return true;
            }
          })();

          // 当用户是普通成员想进管理中心的判定
          if (
            /^\/admin/.test(location.pathname) &&
            isManager === 0 &&
            location.href.indexOf('entity-none') === -1 &&
            location.href.indexOf('entity-notmanager') === -1 &&
            location.href.indexOf('entity-create') === -1 &&
            location.href.indexOf('entity-select') === -1
          ) {
            if (currentEntityUuid && adminEntityList.length === 0) {
              // 没有企业是管理员身份的，跳转至创建企业引导页
              location.href = '/entity-notmanager?toUrl=' + toUrl + '&fromUrl=' + fromUrl;
            } else if (!currentEntityUuid && adminEntityList.length === 0) {
              // 没有企业的，跳转至创建企业引导页
              location.href = '/entity-none?toUrl=' + toUrl + '&fromUrl=' + fromUrl;
            } else if (adminEntityList.length === 1) {
              // 有且只有一家企业是管理员身份的，需要同步激活该企业后进入首页（业务场景：之前有两家管理员企业，但是解散了一家之后再登录，就需要同步激活）
              activateEntity(userUuid, adminEntityList[0].uuid);
            } else if (adminEntityList.length > 1) {
              // 有多家企业是管理员身份的（业务场景，之前有多家管理员企业，但是解散了一家之后再登录，当前的企业uuid只是一个普通员工身份的企业，需要手动选择进入）
              location.href = '/entity-select';
            }
          }
          if (hasAuth) {
            return <Component {...props} />;
          } else {
            return <Redirect to='/403' />;
          }
        } else {
          ywk.goLogin();
          return null;
        }
      }}
    ></Route>
  );
};
const mapStateToProps = state => ({
  profile: state.user.profile,
});
export default connect(mapStateToProps)(AuthRoute);

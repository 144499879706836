import 'react-app-polyfill/ie9';
// react
import React from 'react';
import ReactDOM from 'react-dom';
// antd国际化 https://ant.design/components/locale-provider-cn/
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
// redux, react-redux, @types/react-redux, 单点登录
import store from 'store';
import { Provider } from 'react-redux';
import { saveProfile } from './store/actions/user';
import Cookies from 'js-cookie';
import $ from 'jquery';
import ywk from 'utils';
// 路由入口及全局样式
import App from './App';
import 'assets/css/index.less';
import * as serviceWorker from './serviceWorker';
import * as iframe from 'utils/iframe';

moment.locale('zh-cn');
// 单点登录
(function login() {
  // 由于正式环境/测试环境单点登录异步问题，登录改成jquery方式的同步请求。
  const hostname = window.location.hostname;
  // 单点登录api地址
  const ssoLoginUrl =
    hostname.indexOf('clouderwork') > -1
      ? 'https://passport.clouderwork.com/sso/login'
      : hostname.indexOf('beta') > -1
      ? 'https://beta-passport.yunwoke.com/sso/login'
      : 'http://passport.yunwoke.com/sso/login';
  // 组织机构登录
  const orgLogin = function() {
    $.ajax({
      url: '/api/v1.1/sys/loginInfos',
      async: false,
      dataType: 'json',
      headers: {
        token: Cookies.get('cwsso_token'),
      },
      success: function(res) {
        if (res.code === '0') {
          /**
           * v1.0版本loginInfos 接口直接存 res.content
           * v1.1版本loginInfos 接口 res.content.redis === v1.0 res.content。并在此基础上新增了 res.content.org
           * 由于页面大量引用了 v1.0版本的 res.content 当做profile。此处仅合并对象处理
           * 以后redis可能还会有调整，页面中应尽量避免使用 redis 中的字段
           */
          const content = res.content || {};
          const redis = content.redis || {};
          let orgWeb = content.org || {};
          // 子管理员获取菜单权限
          if (orgWeb.isManager === 2) {
            $.ajax({
              url: '/api/principal.permission.list',
              async: false,
              data: {
                entityId: redis.currentEntityUuid,
                productId: 'clouderworkorg',
                resource: 'backend',
                userId: redis.userUuid,
                onlyUser: 1,
              },
              dataType: 'json',
              headers: {
                token: Cookies.get('cwsso_token'),
              },
              success: function(res) {
                if (res.code === '0') {
                  const permissionList = (res.content && res.content[redis.userUuid]) || [];
                  orgWeb = { ...orgWeb, permissionList };
                }
              },
            });
          }
          const newContent = Object.assign({}, redis, { orgWeb: orgWeb });
          store.dispatch(saveProfile(newContent));

          // 埋点：绑定登录用户 文档 https://shimo.im/docs/prjVCc8TvgR9yPhv
          window['AnalysysAgent'].alias(redis.userUuid);
        } else {
          Cookies.remove('cwsso_token');
          store.dispatch(saveProfile({}));
        }
      },
      error: function(err) {
        Cookies.remove('cwsso_token');
        store.dispatch(saveProfile({}));
      },
    });
  };
  // 测试环境正式环境单点登录。
  const ssoLogin = function() {
    $.ajax({
      url: ssoLoginUrl,
      async: false,
      crossDomain: true,
      dataType: 'json',
      xhrFields: {
        withCredentials: true,
      },
      success: function(res) {
        if (res.error_code === 0) {
          orgLogin();
        } else {
          Cookies.remove('cwsso_token');
          Cookies.remove('token');
          store.dispatch(saveProfile({}));
        }
      },
      error: function(err) {
        Cookies.remove('cwsso_token');
        Cookies.remove('token');
        store.dispatch(saveProfile({}));
      },
    });
  };
  if (hostname.indexOf('clouderwork') > -1 || hostname.indexOf('yunwoke') > -1) {
    const ticket = ywk.getQueryString('ticket');
    if (Cookies.get('cwsso_token') || ticket) {
      orgLogin();
    } else {
      ssoLogin();
    }
  } else {
    orgLogin();
  }
})();
ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

iframe.initChild({}, (parentApi: any) => {
  (window as any).parentApi = parentApi;
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
 * @module: 定义成员相关reducers
 * @Author: 何智
 * @Date: 2019-04-10 17:12:42
 * @Last Modified by: 何智
 * @Last Modified time: 2019-07-18 17:43:30
 */

import { handleActions } from 'redux-actions';
import types from '../types';
import produce from 'immer';

let initialState = {
  // 员工档案信息
  memberInfo: {},
};

export const memberReducer = handleActions(
  {
    [types.GET_MEMBER]: {
      next(state, action) {
        return produce(state, draft => {
          draft.memberInfo = action.payload.content || {};
        });
      },
      throw(state) {
        return state;
      },
    },
    [types.PATCH_MEMBER]: {
      next(state, action) {
        return produce(state, draft => {
          draft.memberInfo = action.payload.content || {};
        });
      },
      throw(state) {
        return state;
      },
    },
  },
  initialState
);

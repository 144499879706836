/**
 * Created by Administrator on 2019/2/15.
 */

import { handleActions } from 'redux-actions';
import produce from 'immer';
import types from '../types';

let initialState = {
  teamVisible: false,
  typeTeam: 'add',
  applyListVisible: false,
  organEditUuid: '',
  groupUUid: '',
  eidtTitle: '',
  // 显示成员弹窗
  memberShow: false,
  // 调整排序弹窗
  sortGroupsVis: false,
  // 调整排序父部门uuid
  sortParentUuid: 0,
};

export const teamVisibleReducer = handleActions(
  {
    //控制加入团队跟其它团队
    [types.TEAM_VISIBLE]: {
      next(state, action) {
        return produce(state, draft => {
          draft.teamVisible = !state.teamVisible;
          draft.typeTeam = action.payload.type === 'add' ? 'add' : 'other';
        });
      },
      throw(state) {
        return state;
      },
    },
    //控制申请人列表
    [types.APPLY_VISIBLE]: {
      next(state, action) {
        return produce(state, draft => {
          draft.applyListVisible = action.payload.visible;
        });
      },
      throw(state) {
        return state;
      },
    },
    // 控制添加部门弹窗
    [types.ADDORGAN]: {
      next(state, action) {
        return produce(state, draft => {
          draft.addOrganVis = action.payload.addOrganVis;
          draft.organEditUuid = action.payload.editUuid;
          draft.organEditVis = action.payload.editVis || false;
        });
      },
      throw(state) {
        return state;
      },
    },
    // 显示成员弹窗
    [types.SHOWMEMBER]: {
      next(state, action) {
        return produce(state, draft => {
          draft.memberShow = action.payload.memberShow;
          draft.groupUUid = action.payload.editUuid;
          draft.eidtTitle = action.payload.eidtTitle;
        });
      },
      throw(state) {
        return state;
      },
    },
    // 控制移动部门弹窗显示隐藏
    [types.REMOVEORGAN]: {
      next(state, action) {
        return produce(state, draft => {
          draft.removeOrganVis = action.payload.removeOrganVis;
          draft.removeUuid = action.payload.uuid;
        });
      },
      throw(state) {
        return state;
      },
    },
    // 控制调整排序部门弹窗显示隐藏
    [types.SORT_GROUPS]: {
      next(state, action) {
        return produce(state, draft => {
          draft.sortGroupsVis = action.payload.sortGroupsVis;
          draft.sortParentUuid = action.payload.parentUuid;
        });
      },
      throw(state) {
        return state;
      },
    },
    // 获取所有部门集合
    [types.GETALLGROPS]: {
      next(state, action) {
        return produce(state, draft => {
          draft.GropsContent = action.payload.content;
        });
      },
      throw(state) {
        return state;
      },
    },
    // 获取企业所有员工
    [types.GETALLMEMBERS]: {
      next(state, action) {
        return produce(state, draft => {
          draft.memberAll = action.payload.content || [];
        });
      },
      throw(state) {
        return state;
      },
    },
    // 分页获取企业员工
    [types.GETALLMEMBERSPAGE]: {
      next(state, action) {
        return produce(state, draft => {
          draft.memberAll = action.payload.content || [];
        });
      },
      throw(state) {
        return state;
      },
    },
    // 编辑企业弹窗
    [types.SETEDITTEAMMODAL]: {
      next(state, action) {
        return produce(state, draft => {
          draft.editTeamModal = action.payload.editTeamModal;
          draft.editTeamUuid = action.payload.uuid || '';
        });
      },
      throw(state) {
        return state;
      },
    },
  },
  initialState
);

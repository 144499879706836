/*
 * @module: 定义实体（公司，团队，组织）相关actions
 * @Author: 何智
 * @Date: 2019-03-24 16:28:00
 * @Last Modified by: Teacher Tang
 * @Last Modified time: 2019-12-18 14:39:30
 */
import types from '../types';
import { createAction } from 'redux-actions';
import ywk from 'utils';
/**
 * 获取单个企业详情和统计信息
 * entityUuid: 该企业对应的uuid
 */
export const getEntityStats = createAction(types.GET_ENTITYSTATS, entityUuid => {
  return ywk.getJson(`/v1.1/entities/${entityUuid}/stats`);
});
/**
 * 获取企业下的所有部门：同时也是部门统计数据
 */
export const getEntityGroups = createAction(types.GET_ENTITYGROUPS, entityUuid => {
  return ywk.getJson(`/v1.1/entities/${entityUuid}/group/${entityUuid}/members/stats`);
});
/**
 * 获取企业下的所有部门：同时也是部门统计数据
 */
export const getEntityGMember = createAction(types.GET_ENTITYGMEMBER, (entityUuid, groupUuid) => {
  return ywk.getJson(`/v1.3/entities/${entityUuid}/group/${groupUuid}/members/tree`);
});
// 获取企业下所有角色: 默认是树形结构
export const getEntityRoles = createAction(types.GET_ENTITYROLES, (entityUuid, params = {}) => {
  return ywk.getJson(`/acm/v2.0/role.list`, {
    recursive: 1,
    entityId: entityUuid,
    productId: 'clouderworkorg',
    ...params,
  });
});
// 获取企业下的绩效空间数据
export const getEntityMonitor = createAction(types.GET_ENTITYMONITOR, entityUuid => {
  return ywk.getJson(`/account/storage/${entityUuid}/info`);
});

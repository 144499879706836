/*
 * @module: 定义基础公用reducers
 * @Author: 何智
 * @Date: 2019-04-10 17:12:42
 * @Last Modified by: 何智
 * @Last Modified time: 2019-07-18 17:43:23
 */

import { handleActions } from 'redux-actions';
import types from '../types';
import produce from 'immer';

let initialState = {
  // 地区信息（省市区三级联动）
  areadata: [],
};

export const basicReducer = handleActions(
  {
    [types.GET_AREADATA]: {
      next(state, action) {
        return produce(state, draft => {
          draft.areadata = action.payload.content || [];
        });
      },
      throw(state) {
        return state;
      },
    },
  },
  initialState
);

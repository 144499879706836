//如果数字含有小数部分，那么可以将小数部分单独取出
//将小数部分的数字转换为字符串的方法：
import _ from 'lodash';
var chnNumChar = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
var chnUnitSection = ['', '万', '亿', '万亿', '亿亿'];
var chnUnitChar = ['', '拾', '佰', '仟'];
var numToChn = function(num) {
  if ((num || '').indexOf('.') > -1) {
    var str = num.toString().slice((num || '').indexOf('.'));
    var a = '元';
    if (typeof str[1] === undefined) {
      return '元整';
    }
    if (str[1]) {
      a += chnNumChar[parseInt(str[1])] + '角';
    }
    if (str[2]) {
      a += chnNumChar[parseInt(str[2])] + '分';
    }
    return a;
  } else {
    return '元整';
  }
};

//定义在每个小节的内部进行转化的方法，其他部分则与小节内部转化方法相同
function sectionToChinese(section) {
  var str = '',
    chnstr = '',
    zero = false,
    count = 0; //zero为是否进行补零， 第一次进行取余由于为个位数，默认不补零
  while (section > 0) {
    var v = section % 10; //对数字取余10，得到的数即为个位数
    if (v === 0) {
      //如果数字为零，则对字符串进行补零
      if (zero) {
        zero = false; //如果遇到连续多次取余都是0，那么只需补一个零即可
        chnstr = chnNumChar[v] + chnstr;
      }
    } else {
      zero = true; //第一次取余之后，如果再次取余为零，则需要补零
      str = chnNumChar[v];
      str += chnUnitChar[count];
      chnstr = str + chnstr;
    }
    count++;
    section = Math.floor(section / 10);
  }
  return chnstr;
}

//定义整个数字全部转换的方法，需要依次对数字进行10000为单位的取余，然后分成小节，按小节计算，当每个小节的数不足1000时，则需要进行补零

function TransformToChinese2(num) {
  let _num = '' + num;

  if (_num.indexOf('.')) {
    _num = _num.slice(0, _num.indexOf('.'));
  }
  var a = numToChn(num);
  num = Math.floor(num);
  var unitPos = 0;
  var strIns = '',
    chnStr = '';
  var needZero = false;

  if (num === 0) {
    return chnNumChar[0] + a;
  }
  while (num > 0) {
    var section = num % 10000;
    if (needZero) {
      chnStr = chnNumChar[0] + chnStr;
    }
    strIns = sectionToChinese(section);
    strIns += section !== 0 ? chnUnitSection[unitPos] : chnUnitSection[0];
    chnStr = strIns + chnStr;
    needZero = section < 1000 && section > 0;
    num = Math.floor(num / 10000);
    unitPos++;
  }

  return chnStr + a;
}
function TransformToChinese(num) {
  let _num = _.trim(num || '');
  if (_num === '' || _num === '.' || _num === '-') {
    return '';
  }
  if (_num.indexOf('e') > -1 || Math.abs(Number(_num)) > 999999999999.99) {
    return '大写转换不能超过±999999999999.99';
  }
  if (_num.indexOf('-') > -1) {
    return '负数' + TransformToChinese2(_num.slice(1));
  } else {
    return TransformToChinese2(_num);
  }
}
export default TransformToChinese;

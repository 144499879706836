/*
 * @module: 定义员工类型常量
 * @Author: 何智
 * @Date: 2019-04-10 17:13:20
 * @Last Modified by: 何智
 * @Last Modified time: 2019-07-18 17:43:47
 */

// 获取员工档案信息
export const GET_MEMBER = 'GET_MEMBER';
// 更新员工档案信息
export const PATCH_MEMBER = 'PATCH_MEMBER';

/**
 * Created by Administrator on 2019/2/15.
 */

export const TEAM_VISIBLE = 'TEAM_VISIBLE';
export const APPLY_VISIBLE = 'APPLY_VISIBLE';
// 控制添加部门弹窗
export const ADDORGAN = 'ADDORGAN';
// 控制移动部门弹窗显示隐藏
export const REMOVEORGAN = 'REMOVEORGAN';
// 控制调整排序部门弹窗显示隐藏
export const SORT_GROUPS = 'SORT_GROUPS';
// 获取所有部门集合
export const GETALLGROPS = 'GETALLGROPS';
// 展示查看员工弹窗
export const SHOWMEMBER = 'SHOWMEMBER';
// 获取企业所有员工
export const GETALLMEMBERS = 'GETALLMEMBERS';
// 分页获取企业员工
export const GETALLMEMBERSPAGE = 'GET_ALL_MEMBERS_PAGE';
// 编辑企业弹窗
export const SETEDITTEAMMODAL = 'SETEDITTEAMMODAL';

/*
 * @module: 定义基础公用的类型常量
 * @Author: 何智
 * @Date: 2019-04-10 17:13:20
 * @Last Modified by: 何智
 * @Last Modified time: 2019-07-18 17:43:40
 */

// 获取地区（省市区三级联动）
export const GET_AREADATA = 'GET_AREADATA';

/**
 * module: store初始化定义
 * module: Teacher Tang
 * date: 2018-09-06
 */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import rootReducers from './reducers';

// let store = createStore(rootReducers, window.devToolsExtension && window.devToolsExtension(), applyMiddleware(thunk, promiseMiddleware));
let store = createStore(rootReducers, applyMiddleware(thunk, promiseMiddleware));
export default store;

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Icon, Modal } from 'antd';
import { connect } from 'react-redux';
import routes from 'routes';
import Cookies from 'js-cookie';
import ywk from 'utils';
import { cloneDeep, intersection } from 'lodash';
import './index.less';

const { SubMenu } = Menu;
const MenuItem = Menu.Item;
// 获取父级菜单
const getMenuItems = (routes, props) => {
  const {
    profile: { orgWeb = {} },
    location: { pathname = '' },
  } = props;
  const isManager = orgWeb.isManager || 0;
  const permissionList = orgWeb.permissionList || [];
  const permissionTags = permissionList.map(item => item.resource || item.permissionTag);
  return routes
    .filter(item => {
      const meta = item.meta || {};
      const isMenu = meta.isMenu || false;
      const authId = meta.authId || [];
      const subAdminHasAuth = authId[0] === '-1' || intersection(authId, permissionTags).length > 0;
      return /^\/admin/.test(pathname)
        ? isMenu && (isManager === 1 || (isManager === 2 && subAdminHasAuth))
        : !item.redirect;
    })
    .map((item, index) => {
      return getSubMenuItems(item, index, props);
    });
};
// const getMenuItems = menus => {
//   if (!menus) {
//     return [];
//   }

//   return menus
//     .filter(item => item.meta && item.meta.title)
//     .map((item, index) => {
//       return getSubMenuItems(item, index);
//     });
// };
// 获取子级菜单
const getSubMenuItems = (item, index, props) => {
  if (item.children && item.children.some(child => child.meta && child.meta.isMenu)) {
    const childrenItems = getMenuItems(item.children, props);
    if (childrenItems && childrenItems.length > 0) {
      const subMenu = (
        <SubMenu
          key={item.path}
          title={
            <>
              <Icon type={item.meta && item.meta.icon} />
              <span className='menu-text'>{item.meta && item.meta.title}</span>
            </>
          }
        >
          {childrenItems}
        </SubMenu>
      );

      return subMenu;
    }
    return null;
  }
  const navItem = (
    <MenuItem key={item.path}>
      <Link to={item.path}>
        <Icon type={item.meta && item.meta.icon} />
        <span className='menu-text'>{item.meta && item.meta.title}</span>
      </Link>
    </MenuItem>
  );

  return navItem;
};
// const getSubMenuItems = (item, index) => {
//   if (
//     item.children &&
//     item.children.some(child => child.meta && child.meta.title && child.meta.isMenu)
//   ) {
//     const childrenItems = getMenuItems(item.children);
//     if (childrenItems && childrenItems.length > 0) {
//       const subMenu = (
//         <SubMenu
//           key={item.path}
//           data-key={item.path}
//           title={
//             <>
//               <Icon type={item.meta.icon} />
//               <span className='menu-text'>{item.meta.title}</span>
//             </>
//           }
//         >
//           {childrenItems}
//         </SubMenu>
//       );

//       return subMenu;
//     }
//     return null;
//   }
//   const navItem = (
//     <MenuItem key={item.path} data-key={item.path}>
//       <Link to={item.path}>
//         <Icon type={item.meta.icon} />
//         <span className='menu-text'>{item.meta.title}</span>
//       </Link>
//     </MenuItem>
//   );

//   return navItem;
// };
// 退出登录
const logout = () => {
  const token = Cookies.get('cwsso_token') || Cookies.get('token');
  const path = window.location.origin;
  Modal.confirm({
    centered: true,
    title: '确定要退出登录吗？',
    onOk: () => {
      ywk
        .getJson('/site/v1.0/sso/logout', {
          token: token,
        })
        .then(res => {
          Cookies.remove('cwsso_token');
          ywk.goLogin('entsignin', path);
        });
    },
  });
};
// 侧边菜单
const SiderMenu = props => {
  const { location } = props;
  const { pathname } = location;

  // 当前路由
  const curRoute = ywk.getCurRoute(routes, pathname) || {};
  const activeMenu = (curRoute.meta && curRoute.meta.activeMenu) || '';
  let formatedRoutes = ywk.formatRoutes(cloneDeep(routes), '');
  if (/^\/admin/.test(pathname)) {
    formatedRoutes = formatedRoutes.filter(item => item.path === '/admin')[0].children;
  } else if (/^\/user/.test(pathname)) {
    formatedRoutes = formatedRoutes.filter(item => item.path === '/user')[0].children;
  }
  const pathArr = pathname.split('/');
  let openKeys = pathArr.length > 3 ? [`/${pathArr[1]}/${pathArr[2]}`] : [];

  return (
    <Menu
      selectedKeys={activeMenu ? [activeMenu] : [pathname]}
      defaultOpenKeys={openKeys}
      mode='inline'
      className='sider-menu'
    >
      {getMenuItems(formatedRoutes, props)}
      {window.location.origin.indexOf('localhost') > -1 && (
        <MenuItem key='logout' onClick={logout}>
          <Icon type='logout' />
          <span className='nav-text'>退出登录</span>
        </MenuItem>
      )}
    </Menu>
  );
};

const mapStateToProps = state => ({
  profile: state.user.profile,
});

export default withRouter(connect(mapStateToProps)(SiderMenu));

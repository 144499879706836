/*
 * @module: 定义实体（公司、团队、组织）相关操作类型常量
 * @Author: 何智
 * @Date: 2019-03-24 16:31:09
 * @Last Modified by: Teacher Tang
 * @Last Modified time: 2019-12-06 14:58:18
 */
// 获取单个企业详情和企业统计信息
export const GET_ENTITYSTATS = 'GET_ENTITYSTATS';
// 获取单个企业下的部门
export const GET_ENTITYGROUPS = 'GET_ENTITYGROUPS';
// 获取企业下多部门和成员
export const GET_ENTITYGMEMBER = 'GET_ENTITYGMEMBER';
// 获取单个企业下的角色
export const GET_ENTITYROLES = 'GET_ENTITYROLES';
// 获取企业下的存储空间
export const GET_ENTITYMONITOR = 'GET_MONITOR';

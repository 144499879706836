/**
 * module: reducer配置
 * author: Teacher Tang
 * date: 2018-09-06
 */

import { combineReducers } from 'redux';
import { userReducer } from './user';
import { entityReducer } from './entity';
import { basicReducer } from './basic';
import { teamVisibleReducer } from './teamMannage';
import { memberReducer } from './member';
const rootReducer = combineReducers({
  user: userReducer,
  entity: entityReducer,
  basic: basicReducer,
  member: memberReducer,
  teamMannage: teamVisibleReducer,
});

export default rootReducer;

/**
 * module: 定义用户相关的reducer
 * author: Teacher Tang
 * date: 2018-09-06
 */

import { handleActions } from 'redux-actions';
import produce from 'immer';
import types from '../types';

let initialState = {
  profile: {},
};

export const userReducer = handleActions(
  {
    // 获取用户信息存入state
    [types.GET_USER]: {
      next(state, action) {
        if (action.payload.content) {
          return produce(state, draft => {
            draft.profile = action.payload.content || {};
          });
        } else {
          return produce(state, draft => {
            draft.profile = {};
          });
        }
      },
      throw(state) {
        return state;
      },
    },
    // 获取用户权限
    [types.GET_PERMISSION]: {
      next(state, action) {
        const {
          profile: { userUuid },
        } = state;
        if (action.payload.content && userUuid && action.payload.content[userUuid]) {
          return produce(state, draft => {
            draft.profile.orgWeb.permissionList = action.payload.content[userUuid] || {};
          });
        }
      },
      throw(state) {
        return state;
      },
    },
    // 存储用户信息
    [types.SAVE_USER]: {
      next(state, action) {
        if (action.payload.user) {
          return produce(state, draft => {
            draft.profile = { ...action.payload.user } || {};
          });
        } else {
          return produce(state, draft => {
            draft.profile = {};
          });
        }
      },
      throw(state) {
        return state;
      },
    },
  },
  initialState
);

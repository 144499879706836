import { lazy } from 'react';
import { LayoutBlank, LayoutBase } from 'layouts';

/**
 * 额外信息控制
 * meta: {
 *   title: '页面标题'。必填字段：也可当做菜单中的文字显示。为后期动态该表document.title做准备。
 *   desc: '详细描述'。选填字段：为后期加入该项目的新员工描述完整路径。
 *   isMenu: true。选填字段：设为true时，表明该路由是一个菜单，需要在侧边栏中显示。其他的一些页面404，login页面等，或者一些详情页，此类不是菜单的，可不写该属性。
 *   icon: '菜单图标'。选填字段：仅需要在菜单路由中设置。不是菜单的，可不写该属性。
 *   activeMenu: '激活的菜单'。选填字段：例如，从列表页进入详情页时，详情页并没有在左侧菜单中，但需要高亮显示列表页菜单。
 *   authId: 选填字段：不写代表不需要登录，也不需要什么权限。例如404，login。 ['-1'] 仅需要登录，例如 ['backend/scoreTaskManage'] 此类，不直接用字符串，而是使用数组字符串，是因为某些
 *   子级菜单，如果都没有权限，则需要隐藏该父级菜单。后端没有建立父子权限关系，只能前端辛苦下了。
 *   pointTitle: 选填字段：需要埋点的页面标题
 * }
 */

export default [
  {
    path: '/login',
    component: lazy(() => import('views/Login')),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/403',
    component: lazy(() => import('components/Page403')),
    meta: {
      title: '抱歉，您无权访问该页面',
      desc: '路径正确，没有权限',
    },
  },
  {
    path: '/404',
    component: lazy(() => import('components/Page404')),
    meta: {
      title: '抱歉，您访问的页面不存在',
      desc: '页面路径错误',
    },
  },
  {
    path: '/500',
    component: lazy(() => import('components/Page500')),
    meta: {
      title: '抱歉，服务器维护中',
      desc: '服务出错',
    },
  },
  {
    path: '/filepreview',
    component: lazy(() => import('views/FilePreview')),
    meta: {
      title: '文件预览',
    },
  },
  {
    path: '/h5/entities/:entityUuid/members/invitations/externals/:inviterUuid',
    component: lazy(() => import('views/H5/InviteJoinOutter')),
    meta: {
      title: '邀请你加入',
      desc: 'h5页面：成员管理-添加成员弹窗-链接邀请/扫码邀请',
    },
  },
  {
    path: '/h5/entities/:entityUuid/personnels/invitations/externals/:inviterUuid',
    component: lazy(() => import('views/H5/InviteJoinOutterNew')),
    meta: {
      title: '邀请你加入',
      desc: 'h5页面：智能人事-入职管理-链接邀请/扫码邀请',
    },
  },
  {
    path: '/h5/entities/:entityUuid/members/invitations/internals/:uuid',
    component: lazy(() => import('views/H5/InviteJoinInner')),
    meta: {
      title: '邀请你加入',
      desc: 'h5页面：成员管理-添加成员弹窗-账号邀请',
    },
  },
  {
    path: '/web/entities/:entityUuid/members/invitations/internals/:uuid',
    component: lazy(() => import('views/Web/InviteJoinInner')),
    meta: {
      title: '邀请你加入',
      desc: 'web端：成员管理-添加成员弹窗-账号邀请',
    },
  },
  {
    path: '/h5/invite-join-success',
    component: lazy(() => import('views/H5/InviteJoinSuccess')),
    meta: {
      title: '邀请你加入',
      desc: 'h5页面：加入成功',
    },
  },
  {
    path: '/h5/invite-join-error',
    component: lazy(() => import('views/H5/InviteJoinError')),
    meta: {
      title: '邀请你加入',
      desc: 'h5页面：加入失败',
    },
  },
  {
    path: '/h5/entities/:entityUuid/members/:memberUuid/activations',
    component: lazy(() => import('views/H5/InviteActive')),
    meta: {
      title: '邀请你加入',
      desc: '成员管理-未激活员工-发送激活邀请',
    },
  },
  {
    path: '/entity-none',
    component: lazy(() => import('views/EntityNone')),
    meta: {
      title: '创建企业',
      desc: '当登录用户名下没有企业',
      authId: ['-1'],
    },
  },
  {
    path: '/entity-notmanager',
    component: lazy(() => import('views/EntityNone/notManager')),
    meta: {
      title: '创建企业',
      desc: '当登录用户有企业但不是主/子管理员身份',
      authId: ['-1'],
    },
  },
  {
    path: '/entity-create',
    component: lazy(() => import('views/EntityCreate')),
    meta: {
      title: '创建企业',
      desc: '当登录用户名下没有企业，或名下有企业但不是主/子管理员身份',
      authId: ['-1'],
    },
  },
  {
    path: '/entity-select',
    component: lazy(() => import('views/EntitySelect')),
    meta: {
      title: '选择企业',
      desc:
        '当登录用户名下有多家是主/子管理员身份的企业时，解散一家企业被移出一家企业再次登录时需要选择企业',
      authId: ['-1'],
    },
  },
  {
    path: '/pay',
    component: lazy(() => import('views/Pay')),
    meta: {
      title: '支付页面',
      desc: '公共页面集成支付功能',
      authId: ['-1'],
    },
  },
  {
    path: '/pay-wechat',
    component: lazy(() => import('views/PayWechat')),
    meta: {
      title: '微信支付',
      desc: '公共页面集成支付功能-微信支付',
      authId: ['-1'],
    },
  },
  {
    path: '/pay-agree',
    component: lazy(() => import('views/PayAgree')),
    meta: {
      title: '支付协议',
      desc: '公共页面集成支付功能-支付协议',
      authId: ['-1'],
    },
  },
  {
    path: '/admin/appstore/approval/condition/:id',
    component: lazy(() => import('views/Appstore/Approval/Condition')),
    meta: {
      title: '条件审批流程',
      desc: '应用管理-审批-新增/编辑审批-条件审批流程',
    },
  },
  {
    path: '/admin',
    component: LayoutBase,
    meta: {
      title: '管理后台',
      desc: '仅主、子管理员可访问的路由',
    },
    children: [
      {
        path: '/dashboard',
        component: lazy(() => import('views/Dashboard')),
        meta: {
          title: '控制台',
          isMenu: true,
          icon: 'compass',
          authId: ['-1'],
        },
      },
      {
        path: '/org',
        component: LayoutBlank,
        meta: {
          title: '组织架构管理',
          isMenu: true,
          icon: 'apartment',
          authId: [
            'backend/memberManage',
            'backend/roleManage',
            // 'backend/departureManage',
            // 'backend/personnelManage',
            'backend/jobManage',
          ],
        },
        children: [
          {
            path: '/members',
            component: lazy(() => import('views/Members/Base')),
            meta: {
              title: '成员管理',
              isMenu: true,
              icon: 'team',
              authId: ['backend/memberManage'],
            },
            children: [
              {
                path: '/staff',
                component: lazy(() => import('views/Members/Staff')),
                meta: {
                  title: '企业成员',
                  desc: '成员管理-企业成员',
                  authId: ['backend/memberManage'],
                  activeMenu: '/admin/org/members',
                },
              },
              {
                path: '/staff/import-members',
                component: lazy(() => import('views/Members/ImportMembers')),
                meta: {
                  title: '批量导入员工',
                  desc: '成员管理-企业成员-批量导入员工',
                  authId: ['backend/memberManage'],
                  activeMenu: '/admin/org/members',
                },
              },
              {
                path: '/staff/import-personnels',
                component: lazy(() => import('views/Members/ImportPersonnels')),
                meta: {
                  title: '批量导入员工',
                  desc: '成员管理-企业成员-批量导入员工',
                  authId: ['backend/memberManage'],
                  activeMenu: '/admin/org/members',
                },
              },
              {
                path: '/staff/import-groups',
                component: lazy(() => import('views/Members/ImportGroups')),
                meta: {
                  title: '批量导入部门',
                  desc: '成员管理-企业成员-批量导入部门',
                  authId: ['backend/memberManage'],
                  activeMenu: '/admin/org/members',
                },
              },
              {
                path: '/not-activated',
                component: lazy(() => import('views/Members/NotActivated')),
                meta: {
                  title: '未激活员工',
                  desc: '成员管理-未激活员工',
                  authId: ['backend/memberManage'],
                  activeMenu: '/admin/org/members',
                },
              },
              {
                path: '/not-audited',
                component: lazy(() => import('views/Members/NotAudited')),
                meta: {
                  title: '待管理员审核',
                  desc: '成员管理-待管理员审核',
                  authId: ['backend/memberManage'],
                  activeMenu: '/admin/org/members',
                },
              },
              {
                path: '/',
                redirect: '/admin/org/members/staff',
              },
            ],
          },
          {
            path: '/roles',
            component: lazy(() => import('views/Roles')),
            meta: {
              title: '角色管理',
              isMenu: true,
              icon: 'idcard',
              authId: ['backend/roleManage'],
            },
          },
          // {
          //   path: '/departure',
          //   component: lazy(() => import('views/Departure')),
          //   meta: {
          //     title: '离职管理',
          //     isMenu: true,
          //     icon: 'user-delete',
          //     authId: ['backend/departureManage'],
          //   },
          // },
          {
            path: '/personnel/departure',
            component: lazy(() => import('views/Personnel/Departure')),
            meta: {
              title: '智能人事',
              desc: '智能人事-人事记录异动-离职员工',
              authId: ['backend/personnelManage'],
              activeMenu: '/admin/org/personnel',
            },
          },
          {
            path: '/personnel/personnel-source',
            component: lazy(() => import('views/Personnel/PersonnelSource')),
            meta: {
              title: '智能人事',
              desc: '智能人事-花名册-员工档案字段管理',
              authId: ['backend/personnelManage'],
              activeMenu: '/admin/org/personnel',
            },
          },
          {
            path: '/personnel/import-personnels',
            component: lazy(() => import('views/Personnel/ImportPersonnels')),
            meta: {
              title: '批量导入员工',
              desc: '智能人事-花名册-批量导入员工',
              authId: ['backend/personnelManage'],
              activeMenu: '/admin/org/personnel',
            },
          },
          // {
          //   path: '/personnel',
          //   component: lazy(() => import('views/Personnel/Base')),
          //   meta: {
          //     title: '智能人事',
          //     isMenu: true,
          //     icon: 'user-delete',
          //     authId: ['backend/personnelManage'],
          //   },
          //   children: [
          //     {
          //       path: '/roster',
          //       component: lazy(() => import('views/Personnel/Roster')),
          //       meta: {
          //         title: '花名册',
          //         desc: '智能人事-花名册',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/entry',
          //       component: lazy(() => import('views/Personnel/Entry')),
          //       meta: {
          //         title: '入职管理',
          //         desc: '智能人事-入职管理',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/turn',
          //       component: lazy(() => import('views/Personnel/Turn')),
          //       meta: {
          //         title: '转正管理',
          //         desc: '智能人事-转正管理',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/contract',
          //       component: lazy(() => import('views/Personnel/Contract')),
          //       meta: {
          //         title: '合同管理',
          //         desc: '智能人事-合同管理',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/quit',
          //       component: lazy(() => import('views/Personnel/Quit')),
          //       meta: {
          //         title: '离职管理',
          //         desc: '智能人事-离职管理',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/change',
          //       component: lazy(() => import('views/Personnel/Change')),
          //       meta: {
          //         title: '人事异动记录',
          //         desc: '智能人事-人事异动记录',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/reports',
          //       component: lazy(() => import('views/Personnel/Report')),
          //       meta: {
          //         title: '人事统计报表',
          //         desc: '智能人事-人事统计报表',
          //         authId: ['backend/personnelManage'],
          //         activeMenu: '/admin/org/personnel',
          //       },
          //     },
          //     {
          //       path: '/',
          //       redirect: '/admin/org/personnel/roster',
          //     },
          //   ],
          // },

          {
            path: '/postrank',
            component: lazy(() => import('views/Postrank')),
            meta: {
              title: '职位职级管理',
              isMenu: true,
              icon: 'gift',
              authId: ['backend/jobManage'],
            },
          },
        ],
      },
      {
        path: '/appstore',
        component: lazy(() => import('views/Appstore/List')),
        meta: {
          title: '应用管理',
          desc: '应用管理-列表页',
          isMenu: true,
          icon: 'appstore',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/detail/:appName',
        component: lazy(() => import('views/Appstore/Detail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-详情页',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/approval/flow',
        component: lazy(() => import('views/Appstore/Approval/Flow')),
        meta: {
          title: '应用管理',
          desc: '应用管理-添加/编辑审批流程',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/approval/record-self/:id',
        component: lazy(() => import('views/Appstore/Approval/RecordSelfDetail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-本平台记录-详情',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/approval/record-other/detail/:id',
        component: lazy(() => import('views/Appstore/Approval/RecordOtherDetail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-迁移记录-详情',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },

      {
        path: '/appstore/approval/holiday-detail/:uuid',
        component: lazy(() => import('views/Appstore/Approval/HolidayDetail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-假期余额详情',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/approval/holiday-balance/:id',
        component: lazy(() => import('views/Appstore/Approval/HolidayRecord')),
        meta: {
          title: '应用管理',
          desc: '应用管理-假期余额-记录明细',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/approval',
        component: lazy(() => import('views/Appstore/Approval/Base')),
        meta: {
          title: '应用管理',
          desc: '应用管理-审批',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
        children: [
          {
            path: '/management/group',
            component: lazy(() => import('views/Appstore/Approval/ManagementGroup')),
            meta: {
              title: '应用管理',
              desc: '应用管理-审批分组管理',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/management',
            component: lazy(() => import('views/Appstore/Approval/Management')),
            meta: {
              title: '应用管理',
              desc: '应用管理-审批管理',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/record-self',
            component: lazy(() => import('views/Appstore/Approval/RecordSelf')),
            meta: {
              title: '应用管理',
              desc: '应用管理-本平台记录',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/record-other/backups',
            component: lazy(() => import('views/Appstore/Approval/RecordOtherBackups')),
            meta: {
              title: '应用管理',
              desc: '应用管理-迁移记录-备份管理',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/record-other',
            component: lazy(() => import('views/Appstore/Approval/RecordOther')),
            meta: {
              title: '应用管理',
              desc: '应用管理-迁移记录',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },

          {
            path: '/holiday-rules',
            component: lazy(() => import('views/Appstore/Approval/HolidayRules')),
            meta: {
              title: '应用管理',
              desc: '应用管理-假期规则',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/holiday-balance',
            component: lazy(() => import('views/Appstore/Approval/HolidayBalance')),
            meta: {
              title: '应用管理',
              desc: '应用管理-假期余额',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          // {
          //   path: '/auth-set',
          //   component: lazy(() => import('views/Appstore/Approval/AuthSet')),
          //   meta: {
          //     title: '应用管理',
          //     desc: '应用管理-审批-应用管理员权限',
          //     authId: ['backend/appManage'],
          //     activeMenu: '/admin/appstore',
          //   },
          // },
          {
            path: '/',
            redirect: '/admin/appstore/approval/management',
          },
        ],
      },
      {
        path: '/appstore/daily/flow',
        component: lazy(() => import('views/Appstore/Daily/Flow')),
        meta: {
          title: '应用管理',
          desc: '应用管理-日志-添加/编辑',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/daily/record/:id',
        component: lazy(() => import('views/Appstore/Daily/RecordDetail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-日志-日志详情',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/daily',
        component: lazy(() => import('views/Appstore/Daily/Base')),
        meta: {
          title: '应用管理',
          desc: '应用管理-日志',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
        children: [
          {
            path: '/template',
            component: lazy(() => import('views/Appstore/Daily/Template')),
            meta: {
              title: '应用管理',
              desc: '应用管理-日志-模板管理',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/comment',
            component: lazy(() => import('views/Appstore/Daily/Comment')),
            meta: {
              title: '应用管理',
              desc: '应用管理-日志-点评管理',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/stats',
            component: lazy(() => import('views/Appstore/Daily/Stats')),
            meta: {
              title: '应用管理',
              desc: '应用管理-日志-日志统计',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/record',
            component: lazy(() => import('views/Appstore/Daily/Record')),
            meta: {
              title: '应用管理',
              desc: '应用管理-日志-日志记录',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          // {
          //   path: '/auth-set',
          //   component: lazy(() => import('views/Appstore/Daily/AuthSet')),
          //   meta: {
          //     title: '应用管理',
          //     desc: '应用管理-日志-应用管理员权限',
          //     authId: ['backend/appManage'],
          //     activeMenu: '/admin/appstore',
          //   },
          // },
          {
            path: '/',
            redirect: '/admin/appstore/daily/template',
          },
        ],
      },
      {
        path: '/appstore/signin/stats-day/:id',
        component: lazy(() => import('views/Appstore/SignIn/StatsDayDetail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-打卡-按日统计详情',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/signin/stats-month/record',
        component: lazy(() => import('views/Appstore/SignIn/StatsMonthDetail')),
        meta: {
          title: '应用管理',
          desc: '应用管理-打卡-按月统计打卡记录',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/signin/rules',
        component: lazy(() => import('views/Appstore/SignIn/Rules')),
        meta: {
          title: '应用管理',
          desc: '应用管理-打卡-添加/编辑打卡规则',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/signin/:id/schedule',
        component: lazy(() => import('views/Appstore/SignIn/Schedule')),
        meta: {
          title: '应用管理',
          desc: '应用管理-打卡-添加/编辑打卡规则',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/signin',
        component: lazy(() => import('views/Appstore/SignIn/Base')),
        meta: {
          title: '应用管理',
          desc: '应用管理-打卡',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
        children: [
          {
            path: '/setting',
            component: lazy(() => import('views/Appstore/SignIn/Setting')),
            meta: {
              title: '应用管理',
              desc: '应用管理-打卡-打卡设置',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/overtime',
            component: lazy(() => import('views/Appstore/SignIn/Overtime')),
            meta: {
              title: '应用管理',
              desc: '应用管理-打卡-加班规则',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/stats-day',
            component: lazy(() => import('views/Appstore/SignIn/StatsDay')),
            meta: {
              title: '应用管理',
              desc: '应用管理-打卡-按日统计',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/stats-month',
            component: lazy(() => import('views/Appstore/SignIn/StatsMonth')),
            meta: {
              title: '应用管理',
              desc: '应用管理-打卡-按月统计',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          // {
          //   path: '/auth-set',
          //   component: lazy(() => import('views/Appstore/SignIn/AuthSet')),
          //   meta: {
          //     title: '应用管理',
          //     desc: '应用管理-打卡-应用管理员权限',
          //     authId: ['backend/appManage'],
          //     activeMenu: '/admin/appstore',
          //   },
          // },
          {
            path: '/',
            redirect: '/admin/appstore/signin/setting',
          },
        ],
      },
      {
        path: '/appstore/payslip/payroll-setting',
        component: lazy(() => import('views/Appstore/Payslip/PayrollSetting')),
        meta: {
          title: '应用管理',
          desc: '应用管理-工资表-上传Excel工资表',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
      },
      {
        path: '/appstore/payslip',
        component: lazy(() => import('views/Appstore/Payslip/Base')),
        meta: {
          title: '应用管理',
          desc: '应用管理-工资表',
          authId: ['backend/appManage'],
          activeMenu: '/admin/appstore',
        },
        children: [
          {
            path: '/payroll-list/detail/:id',
            component: lazy(() => import('views/Appstore/Payslip/PayrollDetail')),
            meta: {
              title: '应用管理',
              desc: '应用管理-工资表-工资条',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/payroll-list/error/:id',
            component: lazy(() => import('views/Appstore/Payslip/PayrollError')),
            meta: {
              title: '应用管理',
              desc: '应用管理-工资表-工资条-异常数据',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          {
            path: '/payroll-list',
            component: lazy(() => import('views/Appstore/Payslip/PayrollList')),
            meta: {
              title: '应用管理',
              desc: '应用管理-工资表',
              authId: ['backend/appManage'],
              activeMenu: '/admin/appstore',
            },
          },
          // {
          //   path: '/auth-set',
          //   component: lazy(() => import('views/Appstore/Payslip/AuthSet')),
          //   meta: {
          //     title: '应用管理',
          //     desc: '应用管理-工资条-应用管理员权限',
          //     authId: ['backend/appManage'],
          //     activeMenu: '/admin/appstore',
          //   },
          // },
          {
            path: '/',
            redirect: '/admin/appstore/payslip/payroll-list',
          },
        ],
      },
      {
        path: '/setting',
        component: lazy(() => import('views/Setting/Base')),
        meta: {
          title: '企业设置',
          isMenu: true,
          icon: 'setting',
          authId: ['backend/companySetting'],
        },
        children: [
          {
            path: '/set-entity',
            component: lazy(() => import('views/Setting/SetEntity')),
            meta: {
              title: '企业设置',
              desc: '企业设置-企业设置',
              activeMenu: '/admin/setting',
              authId: ['backend/companySetting'],
            },
          },
          {
            path: '/set-admin',
            component: lazy(() => import('views/Setting/SetAdmin')),
            meta: {
              title: '更换主管理员',
              desc: '企业设置-更换主管理员',
              activeMenu: '/admin/setting',
              authId: ['backend/companySetting'],
            },
          },
          {
            path: '/dissolve',
            component: lazy(() => import('views/Setting/Dissolve')),
            meta: {
              title: '解散企业',
              desc: '企业设置-解散企业',
              activeMenu: '/admin/setting',
              authId: ['backend/companySetting'],
            },
          },
          {
            path: '/',
            redirect: '/admin/setting/set-entity',
          },
        ],
      },
      {
        path: '/score',
        component: LayoutBlank,
        meta: {
          title: '积分管理',
          authId: ['backend/scoreTaskManage', 'backend/scoreRankManage'],
          isMenu: true,
          icon: 'pay-circle',
        },
        children: [
          {
            path: '/get-manage',
            component: lazy(() => import('views/Integral/GetManage')),
            meta: {
              title: '积分任务管理',
              isMenu: true,
              icon: 'transaction',
              authId: ['backend/scoreTaskManage'],
            },
          },
          {
            path: '/divide-manage',
            component: lazy(() => import('views/Integral/DivideManage')),
            meta: {
              title: '等级划分管理',
              isMenu: true,
              icon: 'ordered-list',
              authId: ['backend/scoreRankManage'],
            },
          },
        ],
      },
      {
        path: '/orders',
        component: LayoutBlank,
        meta: {
          title: '订单中心',
          desc: '仅主、子管理员可见的企业订单',
          isMenu: true,
          icon: 'account-book',
          authId: ['-1'],
        },
        children: [
          {
            path: '/list',
            component: lazy(() => import('views/Orders/List')),
            meta: {
              title: '订单中心',
              desc: '订单列表',
              authId: ['-1'],
              activeMenu: '/admin/orders',
            },
          },
          {
            path: '/detail/:id',
            component: lazy(() => import('views/Orders/Detail')),
            meta: {
              title: '订单详情',
              desc: '订单详情',
              authId: ['-1'],
              activeMenu: '/admin/orders',
            },
          },
          {
            path: '/bank-card',
            component: lazy(() => import('views/Orders/BankCard')),
            meta: {
              title: '订单详情',
              desc: '订单详情',
              authId: ['-1'],
              activeMenu: '/admin/orders',
            },
          },
          {
            path: '/',
            redirect: '/admin/orders/list',
          },
        ],
      },
      {
        path: '/',
        redirect: '/admin/dashboard',
      },
    ],
  },
  {
    path: '/user',
    component: LayoutBase,
    meta: {
      title: '用户中心',
      desc: '仅内部成员可访问的路由',
    },
    children: [
      {
        path: '/orders',
        component: LayoutBlank,
        meta: {
          title: '我的订单',
          desc: '普通内部成员能看到的自己是创建人的订单',
          isMenu: true,
          icon: 'account-book',
          authId: ['-1'],
        },
        children: [
          {
            path: '/list',
            component: lazy(() => import('views/Orders/List')),
            meta: {
              title: '我的订单',
              desc: '订单列表',
              authId: ['-1'],
              activeMenu: '/user/orders',
              pointTitle: '我的订单',
            },
          },
          {
            path: '/detail/:id',
            component: lazy(() => import('views/Orders/Detail')),
            meta: {
              title: '我的订单',
              desc: '订单详情',
              authId: ['-1'],
              activeMenu: '/user/orders',
            },
          },
          {
            path: '/',
            redirect: '/user/orders/list',
          },
        ],
      },
      {
        path: '/',
        redirect: '/user/orders/list',
      },
    ],
  },
  {
    path: '/',
    redirect: '/user/orders/list',
  },
];

import React, { Component } from 'react';
import { Layout, Menu, Dropdown, Icon, message } from 'antd';
import SiderMenu from './components/SiderMenu';
import { getEntityStats } from 'store/actions/entity';
import { connect } from 'react-redux';
import produce from 'immer';
import ywk from 'utils';
import './style.less';

const { Sider, Content, Footer } = Layout;
// 底部版本号
const localJson = require('../../../package.json');
const version = localJson.version;
// 默认企业头像
const defaultEntityLogo = require('./img/default-entity-logo.png');

class LayoutBase extends Component {
  state = {
    // 用户是管理员的其它企业
    entityOthers: [],
  };

  componentDidMount() {
    const { profile } = this.props;
    console.log(localJson.dependencies.antd);
    if (profile.currentEntityUuid) {
      // 获取当前企业信息
      this.props.getEntityStats(profile.currentEntityUuid);
      if (window.location.hostname.indexOf('localhost') > -1) {
        // 获取其它企业
        this.getEntityOthers();
      }
    }
  }
  // 获取用户其它团队信息
  getEntityOthers = () => {
    const { profile: currentEntityUuid } = this.props;
    ywk.getJson('/v1.1/entities/managers/list').then(res => {
      if (res.code === '0') {
        this.setState(
          produce(draft => {
            draft.entityOthers =
              (res.content && res.content.filter(item => item.uuid !== currentEntityUuid)) || [];
          })
        );
      } else {
        this.setState(
          produce(draft => {
            draft.entityOthers = [];
          })
        );
      }
    });
  };
  renderOtherEntities = () => {
    const { entityStats } = this.props;
    const { entityOthers } = this.state;
    const otherEntities = entityOthers.filter(item => item.uuid !== entityStats.uuid);
    const menu = otherEntities.map(item => (
      <Menu.Item key={item.uuid} title={entityStats.entityName}>
        {item.entityName}
      </Menu.Item>
    ));
    return menu;
  };
  // 切换企业
  changeEntity = ({ key }) => {
    const { profile } = this.props;
    if (key !== 'current') {
      message.loading('切换中，请耐心等待', 0);
      ywk.postJson(`/v1.0/users/${profile.userUuid}/entities/${key}`).then(res => {
        if (res.code === '0') {
          window.location.href = '/admin';
        }
      });
    }
  };
  render() {
    const { entityStats } = this.props;
    const isInIframe = window.self !== window.top;
    return (
      <Layout className='app-root'>
        <Sider
          className='app-sider'
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
          }}
          theme='light'
        >
          {!isInIframe && (
            <div className='top'>
              <div
                className='logo'
                style={{
                  backgroundImage: `url(${entityStats.entityLogoUrl || defaultEntityLogo})`,
                }}
              ></div>
              {window.location.hostname.indexOf('localhost') > -1 ? (
                <Dropdown
                  overlay={
                    <Menu onClick={this.changeEntity} className='entity-others'>
                      <Menu.Item key='current' className='current' title={entityStats.entityName}>
                        <Icon type='check' />
                        {entityStats.entityName}
                      </Menu.Item>
                      {this.renderOtherEntities()}
                    </Menu>
                  }
                >
                  <div className='entity-current'>
                    <span className='name' title={entityStats.entityName}>
                      {entityStats.entityName}
                    </span>
                    <Icon type='down' />
                  </div>
                </Dropdown>
              ) : (
                <div className='entity-current'>
                  <span className='name' title={entityStats.entityName}>
                    {entityStats.entityName}
                  </span>
                </div>
              )}
            </div>
          )}
          <div className='bot'>
            <SiderMenu />
          </div>
        </Sider>
        <Layout className='app-main' style={{ marginLeft: 200 }}>
          <Content className='app-page'>{this.props.children}</Content>
          <Footer className='app-footer'>2020 @ 云沃客组织架构管理 {version}</Footer>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  // 用户信息：渲染用户头像
  profile: state.user.profile,
  // 单个企业详情及统计信息
  entityStats: state.entity.entityStats,
});

export default connect(
  mapStateToProps,
  {
    getEntityStats,
  }
)(LayoutBase);

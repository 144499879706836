/*
 * @module: 定义实体（公司，团队，组织）相关reducers
 * @Author: 何智
 * @Date: 2019-03-25 17:19:57
 * @Last Modified by: Teacher Tang
 * @Last Modified time: 2019-12-18 14:38:50
 */

import { handleActions } from 'redux-actions';
import produce from 'immer';
import types from '../types';

let initialState = {
  // 单个企业详情和统计信息
  entityStats: {},
  // 企业下的所有部门
  entityGroups: {},
  entityGMembers: {},
  // 企业下的所有角色
  entityRoles: [],
  // 企业下的绩效存储空间
  entityMonitor: {},
};

export const entityReducer = handleActions(
  {
    // 获取单个企业详情和统计信息
    [types.GET_ENTITYSTATS]: {
      next(state, action) {
        if (action.payload.content) {
          return produce(state, draft => {
            draft.entityStats = action.payload.content || {};
          });
        } else {
          return produce(state, draft => {
            draft.entityStats = {};
          });
        }
      },
      throw(state) {
        return state;
      },
    },
    // 获取企业下的部门数据
    [types.GET_ENTITYGROUPS]: {
      next(state, action) {
        if (action.payload.content) {
          return produce(state, draft => {
            draft.entityGroups = action.payload.content || {};
          });
        } else {
          return produce(state, draft => {
            draft.entityGroups = {};
          });
        }
      },
    },
    // 获取企业下的部门和成员数据
    [types.GET_ENTITYGMEMBER]: {
      next(state, action) {
        if (action.payload.content) {
          return produce(state, draft => {
            draft.entityGMembers = action.payload.content || {};
          });
        } else {
          return produce(state, draft => {
            draft.entityGMembers = {};
          });
        }
      },
    },
    // 获取企业下的角色数据
    [types.GET_ENTITYROLES]: {
      next(state, action) {
        if (action.payload.data) {
          return produce(state, draft => {
            draft.entityRoles = action.payload.data || [];
          });
        } else {
          return produce(state, draft => {
            draft.entityRoles = [];
          });
        }
      },
    },
    // 获取企业下的存储空间
    [types.GET_ENTITYMONITOR]: {
      next(state, action) {
        if (action.payload.data) {
          return produce(state, draft => {
            draft.entityMonitor = action.payload.data || {};
          });
        } else {
          return produce(state, draft => {
            draft.entityMonitor = {};
          });
        }
      },
    },
  },
  initialState
);

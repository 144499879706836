/**
 * module: 定义相关的操作类型常量
 * author: Teacher Tang
 * date: 2018-09-06
 */

import * as user from './user';
import * as entity from './entity';
import * as common from './basic';
import * as member from './member';
import * as teamMannage from './teamMannage';
import * as merbersMannage from './merbersMannage';

export default {
  ...common,
  ...user,
  ...entity,
  ...member,
  ...teamMannage,
  ...merbersMannage,
};

/**
 * module: 定义用户相关的操作类型常量
 * author: Teacher Tang
 * date: 2018-09-06
 */
// 保存用户数据到state
export const SAVE_USER = 'SAVE_USER';
// 获取用户数据并保存到state
export const GET_USER = 'GET_USER';
// 获取用户权限
export const GET_PERMISSION = 'GET_PERMISSION';

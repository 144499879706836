/**
 * module: 公用工具库文件
 * author: Teacher Tang
 * date: 2018-08-31
 */

import Cookies from 'js-cookie';
import axios from 'axios';
import { stringify } from 'qs';
import { cloneDeep } from 'lodash';
import { message } from 'antd';
import routes from 'routes';
import bigWriteMoney from './bigWriteMoney';
import download from './download';

const path = require('path');
const apiPath = '/api';
const pathname = window.location.pathname;
/**
 * 路径拼接函数
 */
const formatRoutes = (routes = [], parentPath = '') => {
  return routes.map(item => {
    if (item.path) {
      const joinPath = path.join(parentPath || '', item.path);
      item.path = joinPath === '/' ? '/' : joinPath.replace(/\/$/, '');
    }

    if (item.children) {
      item.children = formatRoutes(item.children, item.path);
    }
    return item;
  });
};
/**
 * 获取当前路由信息，主要是获取meta字段中的信息
 * 类似库参考：path-to-regexp
 */
const getCurRoute = (routes, pathname) => {
  let arr = pathname.split('/');
  arr.splice(0, 1);
  let regS = '^';
  arr.forEach(s => {
    // eslint-disable-next-line no-useless-escape
    regS += `\/(${s}|:.+?)`;
  });
  regS += `$`;
  var reg = new RegExp(regS);
  const formatedRoutes = formatRoutes(cloneDeep(routes), '');
  let curMenu = {};
  const filterRoutes = function(arr) {
    if (arr && arr.length) {
      arr.forEach(item => {
        if (item.children) {
          filterRoutes(item.children);
        } else {
          if (reg.test(item.path)) {
            curMenu = item;
          }
        }
      });
    }
  };
  filterRoutes(formatedRoutes);
  return curMenu;
};
const curRoute = getCurRoute(routes, pathname);
/**
 * 获取url参数
 */
const getQueryString = (name, path) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let search = path || decodeURI(window.location.search);
  var r = search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};
// 添加请求拦截器
axios.interceptors.request.use(
  function(config) {
    config.headers['distinct-id'] = window.AnalysysAgent.getDistinctId();
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
axios.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    const data = response.data || {};
    const code = data.code || data.error_code || '0';
    const msg = data.message || data.msg || '';
    const authId = (curRoute.meta && curRoute.meta.authId) || [];
    // authId > 0 才表示是需要登录的页面，有些h5页面不需要登录
    if (authId.length > 0 && ((+code === 1 && msg.indexOf('登陆') > -1) || +code === 8001)) {
      goLogin();
    } else if (+code === 403 || +code === 1) {
      message.error(msg);
    }
    return response;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
let errFn = (error, self, isGet) => {
  if (error.response) {
    // 接口返回80001的时候跳转登录
    if (error.response.data.error_code === 80001) {
      // 非get请求或者工作台操作都验证
      if (!isGet || window.location.pathname.indexOf('workbench') >= 0) {
        self.goLogin();
      }
    }
    return error.response.data;
  } else {
    return {
      error_code: 400,
      msg: error.message,
    };
  }
};
const getSiteEnv = () => {
  const origin = window.location.origin;
  const env = origin.includes('clouderwork') ? 'prod' : origin.includes('beta') ? 'beta' : 'test';

  return env;
};
// 获取各站点域名
const getSitePreUrl = (app, siteEnv) => {
  const config = {
    prod: {
      resident: '//zhaopin.clouderwork.com',
      yunxun: '//i.clouderwork.com',
      ent: '//e.clouderwork.com',
      monitor: '//em.clouderwork.com',
      org: '//org.clouderwork.com',
      crm: '//crm2.clouderwork.com',
      appraise: '//appraise.clouderwork.com',
      passport: '//passport.clouderwork.com',
      task: '//task.clouderwork.com',
      www: '//www.clouderwork.com',
      wwwm: '//m.clouderwork.com',
      edoc: '//edoc.clouderwork.com',
      wssent: 'wss://e.clouderwork.com/api/feeds/ws',
      wsstask: 'wss://task.clouderwork.com/api/feeds/ws',
      wssresident: 'wss://zhaopin.clouderwork.com/websocket/feeds',
      eam: '//eam.clouderwork.com',
      news: '//info.clouderwork.com',
      file: '//ifile.clouderwork.com',
      code: '//code.clouderwork.com',
    },
    beta: {
      resident: '//beta-resident.yunwoke.com',
      yunxun: '//beta-yunxun.yunwoke.com',
      ent: '//beta-ent.yunwoke.com',
      monitor: '//beta-monitor.yunwoke.com',
      org: '//beta-org.yunwoke.com',
      crm: '//beta-crm2.yunwoke.com',
      appraise: '//beta-appraise.yunwoke.com',
      passport: '//beta-passport.yunwoke.com',
      task: '//beta-task.yunwoke.com',
      www: '//beta.yunwoke.com',
      wwwm: '//beta-m.yunwoke.com',
      edoc: '//beta-edoc.yunwoke.com',
      wssent: 'wss://beta-ent.yunwoke.com/api/feeds/ws',
      wsstask: 'wss://beta-task.yunwoke.com/api/feeds/ws',
      wssresident: 'wss://beta-resident.yunwoke.com/websocket/feeds',
      eam: '//beta-eam.yunwoke.com',
      news: '//beta-info.yunwoke.com',
      file: '//yunxunfile.yunwoke.com',
      code: '//beta-code.yunwoke.com',
    },
    test: {
      resident: '//resident.yunwoke.com',
      yunxun: '//yunxun.yunwoke.com',
      ent: '//ent.yunwoke.com',
      monitor: '//monitor.yunwoke.com',
      org: '//org2.yunwoke.com',
      crm: '//crm2.yunwoke.com',
      appraise: '//appraise.yunwoke.com',
      passport: '//passport.yunwoke.com',
      task: '//task.yunwoke.com',
      www: '//test.yunwoke.com',
      wwwm: '//m.yunwoke.com',
      edoc: '//edoc.yunwoke.com',
      wssent: 'ws://ent.yunwoke.com/api/feeds/ws',
      wsstask: 'ws://task.yunwoke.com/api/feeds/ws',
      wssresident: 'ws://resident.yunwoke.com/websocket/feeds',
      eam: '//eam.yunwoke.com',
      news: '//info.yunwoke.com',
      file: '//yunxunfile.yunwoke.com',
      code: '//code.yunwoke.com',
    },
  };

  const env = siteEnv || getSiteEnv();
  return config[env][app] || config[env]['www'];
};
/**
 * 跳转登录
 */
const goLogin = (key = 'entsignin', path) => {
  let loc = window.location;
  // 推广大使创建企业页面登录后依然要跳到创建企业页面，带参数。其他情况未登录，再登录先跳企业版
  const source = getQueryString('source');

  // if (loc.hostname === 'localhost' || loc.hostname.indexOf('192.168') >= 0) {
  if (loc.hostname.indexOf('clouderwork') === -1 && loc.hostname.indexOf('yunwoke') === -1) {
    window.location.href = '/login?redirect=' + loc.pathname;
  } else {
    let url = 'http://passport.yunwoke.com/' + key;
    if (loc.href.indexOf('clouderwork') >= 0) {
      url = 'https://passport.clouderwork.com/' + key;
    } else if (loc.href.indexOf('beta') >= 0) {
      url = 'https://beta-passport.yunwoke.com/' + key;
    }
    if (!path) {
      if (source && window.location.pathname === '/entity-create') {
        path = encodeURIComponent(encodeURIComponent(loc.href));
      } else {
        // path = encodeURIComponent(loc.href);
        path = getSitePreUrl('ent');
      }
    } else {
      path = encodeURIComponent(path);
    }
    window.location.href = `${url}?return_url=${path}`;
  }
};

export default {
  assemblingData(data) {
    if (typeof data === 'object') {
      data._xsrf = Cookies.get('_xsrf');
    } else {
      data = {};
      data._xsrf = Cookies.get('_xsrf');
    }
    return data;
  },
  postJson(url, data = {}, responseType = {}) {
    data = this.assemblingData(data);
    return axios
      .post(`${apiPath}${url}`, data, {
        headers: {
          'Content-Type': 'application/json',
          token: Cookies.get('cwsso_token') || Cookies.get('token'),
        },
        ...responseType,
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return errFn(err, this);
      });
  },
  getJson(url, params = {}) {
    return axios
      .get(`${apiPath}${url}?${stringify(params, { arrayFormat: 'repeat' })}`, {
        headers: {
          token: Cookies.get('cwsso_token') || Cookies.get('token'),
        },
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return errFn(err, this, true);
      });
  },
  deleteJson(url, data = {}) {
    data = this.assemblingData(data);
    return axios
      .delete(`${apiPath}${url}`, {
        data,
        headers: {
          token: Cookies.get('cwsso_token') || Cookies.get('token'),
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return errFn(err, this);
      });
  },
  putJson(url, data = {}) {
    data = this.assemblingData(data);
    return axios
      .put(
        `${apiPath}${url}`,
        // qs.stringify(data),
        data,
        {
          headers: {
            token: Cookies.get('cwsso_token') || Cookies.get('token'),
            'Content-Type': 'application/json',
          },
        }
      )
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return errFn(err, this);
      });
  },
  patchJson(url, data = {}) {
    data = this.assemblingData(data);
    return axios
      .patch(`${apiPath}${url}`, data, {
        headers: {
          token: Cookies.get('cwsso_token') || Cookies.get('token'),
          'Content-Type': 'application/json',
        },
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return errFn(err, this);
      });
  },
  postFile(url, data) {
    data.append('_xsrf', Cookies.get('_xsrf'));
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return axios
      .post(`${apiPath}${url}`, data, config)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return errFn(err, this);
      });
  },
  postFileProgress(url, data, callback, source) {
    data.append('_xsrf', Cookies.get('_xsrf'));
    let config = {
      headers: {
        token: Cookies.get('cwsso_token') || Cookies.get('token'),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: callback,
      cancelToken: source.token,
    };
    return axios
      .post(`${apiPath}${url}`, data, config)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err.message);
        } else {
          return errFn(err);
        }
      });
  },
  /**
   *
   * @param {string} url: 接口地址
   * @param {object} data: 参数
   */
  exportExcel(url, data) {
    return axios
      .post(`${apiPath}${url}`, data, {
        headers: {
          'Content-Type': 'application/json',
          token: Cookies.get('cwsso_token') || Cookies.get('token'),
        },
        responseType: 'arraybuffer',
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        return errFn(err, this, true);
      });
  },
  getQueryString,
  getURLParams() {
    var params = {};

    window.location.href.replace(/[#|?&]+([^=#|&]+)=([^#|&]*)/gi, function(m, key, value) {
      params[key] = decodeURIComponent(value);
    });
    return params;
  },
  /**
   * 深度克隆
   * @param {Object} data
   */
  deepCopy(obj, deep) {
    let self = this;
    // 类型判断
    let isArray = item => {
      return Object.prototype.toString.call(obj) === '[object Array]';
    };

    if (obj === null || (typeof obj !== 'object' && typeof obj !== 'function')) {
      return obj;
    }

    if (typeof obj === 'function') {
      // eslint-disable-next-line
      return new Function('return ' + obj.toString())();
    } else {
      var target = isArray(obj) ? [] : {};
      let value = null;

      for (const name of Object.keys(obj)) {
        value = obj[name];
        if (value === obj) {
          continue;
        }

        if (deep && (isArray(value) || typeof value === 'object')) {
          target[name] = self.deepCopy(value, deep);
        } else {
          target[name] = value;
        }
      }
      return target;
    }
  },
  bigWriteMoney,
  /**
   * 转换后端传回的日期串
   * @param {*} val 值
   * @param {*} type 格式
   * @param {*} isMillisecond 是否是毫秒
   */
  getDate(val, type, isMillisecond = true) {
    let time = new Date();
    if (val) {
      time = isMillisecond ? new Date(val * 1000) : new Date(val.toString().replace(/-/g, '/'));
    }
    let year = time.getFullYear();
    let cmonth = time.getMonth() + 1;
    let month = cmonth < 10 ? '0' + cmonth : cmonth;
    let date = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
    let hour = time.getHours();
    hour = hour < 10 ? '0' + hour : hour;
    let minutes = time.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let seconds = time.getSeconds();
    seconds = seconds < 10 ? '0' + seconds : seconds;
    let result = '';
    switch (type) {
      case 'y.m': // 2016.11
        result = year + '.' + month;
        break;
      case 'ym': // 2016.11.23
        result = year + '.' + month + '.' + date;
        break;
      case 'md': // 11.23
        result = month + '.' + date;
        break;
      case 'hm': // 10:56
        result = hour + ':' + minutes;
        break;
      case 'mdhm': // 11-23 10:56
        result = month + '-' + date + ' ' + hour + ':' + minutes;
        break;
      case 'hms': // 10:56:09
        result = hour + ':' + minutes + ':' + seconds;
        break;
      case 'm': // 06
        result = minutes;
        break;
      case 'm-d': // 11-23
        result = month + '-' + date;
        break;
      case 'ymhs': // 2016.11.23 10:56
        result = year + '.' + month + '.' + date + ' ' + hour + ':' + minutes;
        break;
      case 'ymhss': // 2016.11.23 10:56:00
        result = year + '.' + month + '.' + date + ' ' + hour + ':' + minutes + ':' + seconds;
        break;
      case 'ymdhms': // 20161123105600
        result = year + '' + month + '' + date + '' + hour + '' + minutes + '' + seconds;
        break;
      case 'y-mhs': // 2016-11-23 10:56
        result = year + '-' + month + '-' + date + ' ' + hour + ':' + minutes;
        break;
      case 'ymdhm': // 2016-11-23
        result = year + '-' + month + '-' + date;
        break;
      case 'mdyr': // 11月23日
        result = month + '月' + date + '日 ';
        break;
      case 'ymd': // 2013年11月23日
        result = year + '年' + month + '月' + date + '日 ';
        break;
      case 'year': // 2016
        result = year;
        break;
      case 'task':
        let now = new Date().getTime();
        let day = new Date().getDate();
        let diff = now - time;
        result = year + '-' + month + '-' + date + ' ' + hour + ':' + minutes;
        if (diff < 86400000) {
          result =
            time.getDate() - day === 0
              ? '今天 ' + hour + ':' + minutes
              : '昨天 ' + hour + ':' + minutes;
        } else if (diff < 86400000 * 2) {
          result = day - time.getDate() === 1 ? '昨天 ' + hour + ':' + minutes : result;
        }
        break;
      default:
        result = year + '-' + month;
    }
    return result;
  },

  /**
   * 格式化金钱， 加千位分隔符，且保留两位小数
   * @param {*} val 金额 单位：分
   * @param {*} isWan 是否显示万
   */
  getMoney(val, isWan) {
    val = val || 0;
    if (isWan && val >= 1000000) {
      return val / 1000000 + '万';
    } else {
      return (val / 100).toFixed(2).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
    }
  },
  /**
   *
   * @param {*获取附件size} val
   */
  getSize(val) {
    if (val < 1048576) {
      return (val / 1024).toFixed(2) + 'KB';
    } else {
      return (val / 1048576).toFixed(2) + 'MB';
    }
  },
  getEnvOrigin() {
    let origin = window.location.origin;
    let envOrigin =
      origin.indexOf('clouderwork') > -1
        ? 'https://org.clouderwork.com'
        : origin.indexOf('beta') > -1
        ? 'https://beta-org.yunwoke.com'
        : 'https://org2.yunwoke.com';
    return envOrigin;
  },
  goLogin,
  getLoginUrl(key = 'entsignin', path) {
    let loc = window.location;
    if (loc.hostname === 'localhost' || loc.hostname.indexOf('191.168') >= 0) {
      return '/login?redirect=' + loc.pathname;
    } else {
      let url = 'http://passport.yunwoke.com/' + key;
      if (loc.href.indexOf('clouderwork') >= 0) {
        url = 'https://passport.clouderwork.com/' + key;
      }
      if (!path) {
        path = encodeURIComponent(loc.href);
      } else {
        path = encodeURIComponent(path);
      }
      return `${url}?return_url=${path}`;
    }
  },

  /**
   * 获取应用图标
   *
   * @param {*} appName
   */
  getAppIcon(appName) {
    try {
      return require(`assets/img/appstore/${appName}.png`);
    } catch (error) {
      return require(`assets/img/appstore/default-app.png`);
    }
  },
  /**
   * 节流函数
   * @param {Function} fn 被执行行数
   * @param {Number} wait 需要等待的时间(毫秒)
   * @param {Number} mustRun 多少毫秒必须执行一次
   */
  throttle(fn, wait, mustRun) {
    let timeout = null;
    let start = new Date();
    return () => {
      let ctx = this;
      let args = arguments;
      let curTime = new Date();
      clearTimeout(timeout);
      // 如果达到了规定的触发时间间隔，触发 handler
      if (curTime - start >= mustRun) {
        fn.apply(ctx, args);
        start = curTime;
        // 没达到触发间隔，重新设定定时器
      } else {
        timeout = setTimeout(fn, wait);
      }
    };
  },
  // 获取主站链接
  getHost() {
    const origin = window.location.origin;
    if (origin.indexOf('clouderwork') >= 0) {
      return 'https://www.clouderwork.com';
    } else if (origin.indexOf('beta') >= 0) {
      return 'https://beta.yunwoke.com';
    } else {
      return 'https://test.yunwoke.com';
    }
  },
  /**
   * 数组去重
   * 示例：[1, 1, "1", "1", null, null, undefined, undefined, new String("1"), new String("1"), /a/, /a/, NaN, NaN, {a:1}, {a:1}]
   * 去重后：[1, "1", null, undefined, String, /a/, NaN, {a: 1}]
   * @param {array} array
   */
  unique(array) {
    let obj = {};
    return array.filter(function(item, index, array) {
      return obj.hasOwnProperty(typeof item + JSON.stringify(item))
        ? false
        : (obj[typeof item + JSON.stringify(item)] = true);
    });
  },
  /**
   * 手机号加密 13200001111 => 132****1111
   */
  encryptPhone(phone) {
    phone = '' + phone;
    return phone.replace(phone.substring(3, 7), '****');
  },
  /**
   * 根据登录loginInfos判断是否有权限(是否显示该菜单)
   */
  hasPermission(profile = {}, tag = '') {
    // 0: 普通成员， 1：主管理员，2：子管理员
    const isManager = profile.orgWeb && profile.orgWeb.isManager;
    const permissions = (profile.orgWeb && profile.orgWeb.permissionList) || [];
    if (isManager === 1) {
      return true;
    } else {
      return permissions.findIndex(item => item.permissionTag === tag) > -1;
    }
  },
  /**
   * 路径拼接函数
   */
  formatRoutes,
  /**
   * 获取当前路由信息，主要是获取meta字段中的信息
   * 类似库参考：path-to-regexp
   */
  getCurRoute,
  // 获取员工头像地址
  getAvatarPath(userId, entityUuid) {
    const baseUrl = this.getSitePreUrl('org');
    return `${baseUrl}/api/oa/head/users/${userId}/entities/${entityUuid}`;
    // const origin = window.location.origin;
    // const avatarPath =
    //   origin.indexOf('clouderwork') > -1
    //     ? 'https://www.clouderwork.com/api/v2/user/avatar'
    //     : origin.indexOf('beta') > -1
    //     ? 'https://beta.yunwoke.com/api/v2/user/avatar'
    //     : 'https://test.yunwoke.com/api/v2/user/avatar';
    // return avatarPath;
  },
  getSiteEnv,
  // 获取各站点域名
  getSitePreUrl,
  // 生成唯一id
  guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  formatComment(content) {
    const newContent = content.replace(/<@([^|]+)\|([^>]+)>/g, '<span class="at">@$2</span>');
    return newContent;
  },
  download,
  // localStorage处理
  getStorage(name) {
    const val = localStorage.getItem(name);
    return val ? JSON.parse(val) : null;
  },
  setStorage(name, obj) {
    localStorage.setItem(name, JSON.stringify(obj));
  },
  delStorage(name) {
    localStorage.removeItem(name);
  },
  filePreview(file) {
    const { name, url } = file;
    const fileType = name
      .split('.')
      .pop()
      .toLocaleLowerCase();
    const wpsFile = [
      'doc',
      'dot',
      'wps',
      'wpt',
      'docx',
      'docm',
      'dotm',
      'rtf',
      'xls',
      'xlt',
      'et',
      'xlsx',
      'csv',
      'xlsm',
      'xltm',
      'ppt',
      'pptx',
      'pptm',
      'ppsx',
      'pps',
      'potx',
      'potm',
      'dpt',
      'dps',
      'pdf',
    ];
    const otherFile = ['mp4', 'avi', 'flv', 'm4v', 'mkv', 'mov', 'mpeg', 'txt', 'mp3'];

    if (wpsFile.includes(fileType)) {
      this.getJson('/oa/v1.0/common/file/preview', {
        file_url: encodeURIComponent(url),
        file_name: encodeURIComponent(name),
      }).then(res => {
        if (res.error_code === 0) {
          window.open(res.data);
        }
      });
    } else if (otherFile.includes(fileType)) {
      window.open(
        `https://${
          this.getSiteEnv() === 'prod' ? 'ifile.clouderwork.com' : 'ifile.yunwoke.com'
        }/onlinePreview?url=${encodeURIComponent(url || '')}`
      );
    } else {
      message.info('该文件暂不支持预览');
    }
  },
  downloadBlob(blob, fileName, cb) {
    let fileReader = new FileReader();
    fileReader.onload = function() {
      try {
        let res = JSON.parse(this.result);
        if (res.code === 0) {
          message.success('上传成功');
          cb && cb();
        } else {
          message.error(res.message || '请求错误，请稍后重试');
        }
      } catch (err) {
        message.error('存在错误数据');
        // 可能对了一半，对的那一半正常执行回调，错的那一半数据下载下来
        cb && cb();
        const fileBlob = new Blob([blob], { type: 'application/vnd.ms-excel' });
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(fileBlob, fileName || '错误数据');
        } else {
          const elink = document.createElement('a');
          elink.download = fileName || '错误数据';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(fileBlob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        }
      }
    };
    fileReader.readAsText(blob, 'utf-8');
  },
};

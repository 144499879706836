/*
 * @module: 页面加载中
 * @Author: 何智
 * @Date: 2019-06-12 16:15:31
 * @Last Modified by:   何智
 * @Last Modified time: 2019-06-12 16:15:31
 */

import React from 'react';
import { Spin } from 'antd';

// loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport
export default () => (
  <div style={{ paddingTop: 100, textAlign: 'center' }}>
    <Spin size='large' />
  </div>
);

import { Postmate } from './postmate';

/**
 * 初始化父页面
 * @export
 * @function
 * @param {Object} config 配置
 * @param {Function} cb 接收API函数
 */
export const initParent = (config, cb) => {
  const {
    container = typeof container !== 'undefined' ? container : document.body, // eslint-disable-line no-use-before-define
    model,
    url,
    classListArray = [],
    onLoad = () => {},
    onError = () => {},
  } = config;

  const instance = new Postmate({
    container,
    model,
    url,
    classListArray,
    onLoad,
    onError,
  });

  instance.then(parent => {
    cb(parent);
  });
};

/**
 * 初始化子页面
 * @export
 * @function
 * @param {Object} modal 上报数据
 * @param {Function} cb 接收API函数
 */
export const initChild = (modal, cb) => {
  const instance = new Postmate.Model(modal);

  instance.then(child => {
    cb(child);
  });
};

/**
 * 销毁API
 * @export
 * @function
 * @param {Object} instance 通信API
 */
export const destroy = instance => {
  if (instance && instance.destroy) {
    instance.destroy();
  }
};

/**
 * 切换debug模式
 * @export
 * @function
 * @param {Boolean} flag 是否开启
 */
export const toggleDebug = flag => {
  Postmate.debug = !!flag;
};
